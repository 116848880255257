<template>
  <div id="app">
    <div class="container">
      <div id="snackbar"></div>
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-1-1-1-11
              variant="info"
              >Select Country</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-1-1-1-11"
            visible
            accordion="my-accordion-1-1-1-11"
            role="tabpanel"
          >
            <b-card-body>
              <div role="tablist">
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Select Country</label>
                      <!-- <b-form-input
                        type="text"
                        required
                        v-model="event_info.e_name"
                        id="Event_Name"
                        placeholder="Name"
                      ></b-form-input> -->
                      <b-form-select
                        v-model="event_info.country_id"
                        id="em_name"
                        :plain="true"
                        text-field="CountryName"
                        value-field="CountryID"
                        :options="country_options"
                      >
                        <template slot="first">
                          <option :value="null">Select Country</option>
                        </template>
                      </b-form-select>
                      <!-- <b-form-select
                        class="form-control"
                        v-model="event_info.country_id"
                        :options="country_options"
                        @input="countryChange()"
                      ></b-form-select> -->
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" v-if="$route.query.event_id">
                    <label for="Title">Event Link : </label>
                    <a
                      :href="`https://www.wanasatime.com/event-details/${$route.query.event_id}`"
                      target="_blank"
                      >{{ " " + event_info.e_name }}</a
                    >
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-1-1-1
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Event Information</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-1-1-1"
            accordion="my-accordion-1-1-1"
            role="tabpanel"
          >
            <b-card-body>
              <div role="tablist">
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Event Name</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="event_info.e_name"
                        id="Event_Name"
                        placeholder="Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="logo"> Event Image </label>
                      <b-form-file
                        @change="
                          handleFileUpload('event_info.e_image', $event, 0)
                        "
                        accept="image/jpeg, image/png, image/gif, image/avif"
                        :plain="true"
                      ></b-form-file>
                      <input type="hidden" />
                      <p class="mt-2">Upload Size : 325 x 488</p>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" v-if="event_info.e_image">
                    <b-img
                      height="100px"
                      width="100px"
                      thumbnail
                      fluid
                      :src="
                        'https://adminapi-wanastime.wanasatime.com/uploads/' +
                        event_info.e_image
                      "
                      alt="Image 1"
                    ></b-img>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="Title">Event Short Description</label>
                      <vue-editor
                        v-model="event_info.e_s_description"
                      ></vue-editor>
                      <!-- <b-form-textarea
                        type="text"
                        required
                        :maxlength="max_char"
                        v-model="event_info.e_s_description"
                        id="Title"
                        placeholder="Enter Description"
                      ></b-form-textarea> -->
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="Title">Event Long Description</label>
                      <vue-editor
                        v-model="event_info.e_description"
                      ></vue-editor>
                      <!-- <b-form-textarea
                        type="text"
                        required
                        v-model="event_info.e_description"
                        id="Title"
                        placeholder="Enter Description"
                      ></b-form-textarea> -->
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="Title">Start Date</label>
                      <b-form-input
                        type="date"
                        required
                        v-model="event_info.es_date"
                        id="Title"
                        placeholder="Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="Title">End Date</label>
                      <b-form-input
                        type="date"
                        required
                        v-model="event_info.ee_date"
                        id="Title"
                        placeholder="Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="Title">Book Type</label>
                      <b-form-select
                        class="form-control"
                        label="booking_type"
                        v-model="event_info.e_book_type"
                        :options="booking_type_options"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="2">
                    <b-form-group>
                      <label for="Title">Event Public</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="event_info.e_public"
                        id="Event_Public"
                        placeholder="1"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="Title">Term & Condition</label>
                      <vue-editor
                        v-model="event_info.terms_condition"
                      ></vue-editor>
                      <!-- <b-form-textarea
                        type="text"
                        required
                        v-model="event_info.terms_condition"
                        id="Title"
                        placeholder="Enter Description"
                      ></b-form-textarea> -->
                    </b-form-group>
                  </b-col>
                  <!-- <b-col sm="3">
                    <b-form-group>
                      <label for="Title">Event Category Id</label>
                      <b-form-textarea
                        type="text"
                        required
                        v-model="event_info.ec_id"
                        id="Title"
                        placeholder="Enter Category Id"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col> -->
                  <b-col sm="6">
                    <b-row>
                      <b-col sm="4">
                        <label for="Title">Booking Open </label>
                        <b-form-checkbox
                          v-model="event_info.booking_open"
                          name="check-button"
                          switch
                        >
                        </b-form-checkbox>
                      </b-col>

                      <b-col sm="4">
                        <label for="Title">Private Event </label>
                        <b-form-checkbox
                          v-model="event_info.is_unlisted"
                          name="check-button"
                          switch
                        >
                        </b-form-checkbox>
                      </b-col>
                      <b-col sm="4">
                        <label for="Title">Free Listing </label>
                        <b-form-checkbox
                          v-model="event_info.is_free"
                          name="check-button"
                          switch
                        >
                        </b-form-checkbox>
                      </b-col>
                      <b-col sm="4" class="mt-3">
                        <label for="Title">Stop Sale </label>
                        <b-form-checkbox
                          v-model="event_info.stop_sale"
                          name="check-button"
                          switch
                        >
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="Title">Sensor Popup Information</label>
                      <b-form-textarea
                        type="text"
                        required
                        v-model="event_info.additional_information"
                        id="info"
                        placeholder="Enter Description"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group>
                      <label for="Title">Age Limit</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="event_info.rating"
                        id="rating"
                        placeholder="For Eg: 21+"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Genre Data -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-2-1-1
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Genre</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-2-1-1"
            accordion="my-accordion-2-1-1"
            role="tabpanel"
          >
            <b-card-body>
              <div
                role="tablist"
                v-for="(item, index) in event_info.e_genreData"
                :key="index"
              >
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Genre Name</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.e_genreData"
                        id="Title"
                        placeholder="Enter Genre"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="4">
                    <label for="Title" class="rmv-btn-alignment"></label>
                    <b-button
                      @click="removeObj(event_info.e_genreData, index)"
                      size="sm"
                      variant="danger"
                    >
                      <i class="fa fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col sm="3">
                  <b-button
                    @click="
                      addMultiple(event_info.e_genreData, {
                        e_genreData: '',
                      })
                    "
                    size="sm"
                    variant="success"
                  >
                    <i class="fa fa-dot-circle-o"></i> Add More Genre
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Cast Crew Accordion -->

        <!-- <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-3-1-1
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Cast Crew</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-3-1-1"
            accordion="my-accordion-3-1-1"
            role="tabpanel"
          >
            <b-card-body>
              <div
                role="tablist"
                v-for="(item, index) in event_info.cast_crew"
                :key="index"
              >
                <b-row>
                  <b-col sm="4">
                    <label for="Title">Cast Crew Name</label>
                    <b-form-input
                      type="text"
                      v-model="item.cc_name"
                      id="Title"
                      placeholder="Enter Name"
                    ></b-form-input>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="logo"> Cast Crew Image </label>
                      <b-form-file
                        @change="handleFileUpload('cc_picture', $event, index)"
                        accept="image/jpeg, image/png, image/gif"
                        :plain="true"
                      ></b-form-file>
                      <input type="hidden" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <label for="Title" class="rmv-btn-alignment"></label>
                    <b-button
                      @click="removeObj(event_info.cast_crew, index)"
                      size="sm"
                      variant="danger"
                    >
                      <i class="fa fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title" class="status-alignment"
                        >Cast Crew Type</label
                      >
                      <b-form-select
                        class="form-control"
                        label="cc_type"
                        v-model="item.cc_type"
                        :options="cc_type_options"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col sm="3">
                  <b-button
                    @click="
                      addMultiple(event_info.cast_crew, {
                        cc_name: '',
                        cc_type: '',
                        cc_picture: '',
                      })
                    "
                    size="sm"
                    variant="success"
                  >
                    <i class="fa fa-dot-circle-o"></i> Add Cast Crew
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card> -->

        <!-- Event Banner Accordion -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-4-1-1
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Event Banner</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-4-1-1"
            accordion="my-accordion-4-1-1"
            role="tabpanel"
          >
            <b-card-body>
              <div
                role="tablist"
                v-for="(item, index) in event_info.e_banners"
                :key="index"
              >
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="logo"> Event Banner Image </label>
                      <b-form-file
                        @change="handleFileUpload('e_banners', $event, index)"
                        accept="image/jpeg, image/png, image/gif"
                        :plain="true"
                      ></b-form-file>
                      <input type="hidden" />
                      <p class="mt-2">Upload Size : 1920 x 575</p>
                    </b-form-group>
                    <b-img
                      v-if="event_info.e_banners[index]['e_banners']"
                      width="350px"
                      class="mb-3"
                      thumbnail
                      fluid
                      :src="
                        'https://adminapi-wanastime.wanasatime.com/uploads/' +
                        event_info.e_banners[index]['e_banners']
                      "
                      alt="Image 1"
                    ></b-img>
                  </b-col>
                  <b-col sm="4">
                    <label for="Title" class="rmv-btn-alignment"></label>
                    <b-button
                      @click="removeObj(event_info.e_banners, index)"
                      size="sm"
                      variant="danger"
                    >
                      <i class="fa fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col sm="3">
                  <b-button
                    @click="
                      addMultiple(event_info.e_banners, {
                        e_banners: '',
                      })
                    "
                    size="sm"
                    variant="success"
                  >
                    <i class="fa fa-dot-circle-o"></i> Add Event Banner
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Event Video Accordion -->

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-5-1-1
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Event Video</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-5-1-1"
            accordion="my-accordion-5-1-1"
            role="tabpanel"
          >
            <!-- <b-card-body>
              <div
                role="tablist"
                v-for="(item, index) in event_info.e_videos"
                :key="index"
              >
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="logo"> Event Video </label>
                      <b-form-file
                        @change="handleVideoFileUpload('e_videos', $event, index)"
                        accept="Video/mp4"
                        :plain="true"
                      ></b-form-file>
                      <input type="hidden" v-model="item.e_videos" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <label for="Title" class="rmv-btn-alignment"></label>
                    <b-button
                      @click="removeObj(event_info.e_videos, index)"
                      size="sm"
                      variant="danger"
                    >
                      <i class="fa fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col sm="3">
                  <b-button
                    @click="
                      addMultiple(event_info.e_videos, {
                        e_videos: '',
                      })
                    "
                    size="sm"
                    variant="success"
                  >
                    <i class="fa fa-dot-circle-o"></i> Add Event Video
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body> -->

            <b-card-body>
              <div
                role="tablist"
                v-for="(item, index) in event_info.e_videos"
                :key="index"
              >
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Event Video</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.e_videos"
                        id="Title"
                        placeholder="Enter Video Link"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="4">
                    <label for="Title" class="rmv-btn-alignment"></label>
                    <b-button
                      @click="removeObj(event_info.e_videos, index)"
                      size="sm"
                      variant="danger"
                    >
                      <i class="fa fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col sm="3">
                  <b-button
                    @click="
                      addMultiple(event_info.e_videos, {
                        e_videos: '',
                      })
                    "
                    size="sm"
                    variant="success"
                  >
                    <i class="fa fa-dot-circle-o"></i> Add Event Video
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Organization Accordion -->

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-6-1-1
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Organiser Details</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-6-1-1"
            accordion="my-accordion-6-1-1"
            role="tabpanel"
          >
            <b-card-body>
              <div
                role="tablist"
                v-for="(item, index) in event_info.organiser_details"
                :key="index"
              >
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Organizer Name</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.od_name"
                        id="Title"
                        placeholder="Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="logo"> Organiser Logo </label>
                      <b-form-file
                        @change="handleFileUpload('od_logo', $event, index)"
                        accept="image/jpeg, image/png, image/gif"
                        :plain="true"
                      ></b-form-file>
                      <input type="hidden" />
                      <p class="mt-2">Upload Size : 100 x 100</p>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <label for="Title" class="rmv-btn-alignment"></label>
                    <b-button
                      @click="removeObj(event_info.organiser_details, index)"
                      size="sm"
                      variant="danger"
                    >
                      <i class="fa fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="Title">Organiser Short Description</label>
                      <vue-editor v-model="item.od_sdescription"></vue-editor>
                      <!-- <b-form-textarea
                        type="text"
                        required
                        :maxlength="max_char"
                        v-model="item.od_sdescription"
                        id="Title"
                        placeholder="Enter Description"
                      ></b-form-textarea> -->
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="Title">Organiser Long Description</label>
                      <vue-editor v-model="item.od_description"></vue-editor>
                      <!-- <b-form-textarea
                        type="text"
                        required
                        v-model="item.od_description"
                        id="Title"
                        placeholder="Enter Description"
                      ></b-form-textarea> -->
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Email</label>
                      <b-form-textarea
                        type="email"
                        required
                        v-model="item.od_email"
                        id="Title"
                        placeholder="Enter Email"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Mobile</label>
                      <b-form-textarea
                        type="text"
                        required
                        v-model="item.od_mobile"
                        id="Title"
                        placeholder="Enter Mobile"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col sm="3">
                  <b-button
                    @click="
                      addMultiple(event_info.organiser_details, {
                        od_name: '',
                        od_sdescription: '',
                        od_description: '',
                        od_logo: '',
                        od_email: '',
                        od_mobile: '',
                      })
                    "
                    size="sm"
                    variant="success"
                  >
                    <i class="fa fa-dot-circle-o"></i> Add Organiser
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Sponser Details -->
        <!-- <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-7-1-1
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Sponsor Details</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-7-1-1"
            accordion="my-accordion-7-1-1"
            role="tabpanel"
          >
            <b-card-body>
              <div
                role="tablist"
                v-for="(item, index) in event_info.sponsor_details"
                :key="index"
              >
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Sponsor Name</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.sd_name"
                        id="Title"
                        placeholder="Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="logo"> Sponser Logo </label>
                      <b-form-file
                        @change="handleFileUpload('sd_logo', $event, index)"
                        accept="image/jpeg, image/png, image/gif"
                        :plain="true"
                      ></b-form-file>
                      <input type="hidden" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <label for="Title" class="rmv-btn-alignment"></label>
                    <b-button
                      @click="removeObj(event_info.sponsor_details, index)"
                      size="sm"
                      variant="danger"
                    >
                      <i class="fa fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Sponsor Short Description</label>
                      <b-form-textarea
                        type="text"
                        required
                        :maxlength="max_char"
                        v-model="item.sd_sdescription"
                        id="Title"
                        placeholder="Enter Description"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="Title">Sponsor Long Description</label>
                      <b-form-textarea
                        type="text"
                        required
                        v-model="item.sd_description"
                        id="Title"
                        placeholder="Enter Description"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Email</label>
                      <b-form-textarea
                        type="email"
                        required
                        v-model="item.sd_email"
                        id="Title"
                        placeholder="Enter Email"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Mobile</label>
                      <b-form-textarea
                        type="text"
                        required
                        v-model="item.sd_mobile"
                        id="Title"
                        placeholder="Enter Mobile"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col sm="3">
                  <b-button
                    @click="
                      addMultiple(event_info.sponsor_details, {
                        sd_name: '',
                        sd_sdescription: '',
                        sd_description: '',
                        sd_logo: '',
                        sd_email: '',
                        sd_mobile: '',
                      })
                    "
                    size="sm"
                    variant="success"
                  >
                    <i class="fa fa-dot-circle-o"></i> Add Sponsor
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card> -->

        <!-- Venue Details -->

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-8-1-1
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Venue Details</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-8-1-1"
            accordion="my-accordion-8-1-1"
            role="tabpanel"
          >
            <b-card-body>
              <div
                role="tablist"
                v-for="(item, index) in event_info.venue"
                :key="index"
              >
                <b-row>
                  <!-- <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Event Id</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.event_id"
                        id="Title"
                        placeholder="Id"
                      ></b-form-input>
                    </b-form-group>
                  </b-col> -->
                  <b-col sm="8">
                    <b-form-group>
                      <label for="Title">Venue Name</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.ev_name"
                        id="Title"
                        placeholder="Name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="2">
                    <label for="Title" class="rmv-btn-alignment"></label>
                    <b-button
                      @click="removeObj(event_info.venue, index)"
                      size="sm"
                      variant="danger"
                    >
                      <i class="fa fa-times"></i>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="8">
                    <b-form-group>
                      <label for="Title">Venue Address</label>
                      <b-form-textarea
                        type="text"
                        required
                        v-model="item.ev_address"
                        id="Title"
                        placeholder="Address"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Latitude</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.ev_lat"
                        id="Title"
                        placeholder="Latitude"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Longitude</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.ev_long"
                        id="Title"
                        placeholder="Longitude"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">City</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.ev_city"
                        id="Title"
                        placeholder="Enter City"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group>
                      <label for="Title">Country</label>
                      <b-form-input
                        type="text"
                        required
                        v-model="item.ev_country"
                        id="Title"
                        placeholder="Enter Countrty"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      href="#"
                      v-b-toggle="'accordion-3-1' + index"
                      variant="warning"
                      >Auditorium Details</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-3-1' + index"
                    accordion="my-accordion-3-1"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <div
                        role="tablist"
                        v-for="(item1, index) in item.auditorium"
                        :key="index"
                      >
                        <b-row>
                          <!-- <b-col sm="4">
                            <b-form-group>
                              <label for="Title">Event Venue Id</label>
                              <b-form-input
                                type="text"
                                required
                                v-model="item1.event_venue_id"
                                id="Title"
                                placeholder="Enter Id"
                              ></b-form-input>
                            </b-form-group>
                          </b-col> -->
                          <b-col sm="8">
                            <b-form-group>
                              <label for="Title">Auditorium Name</label>
                              <b-form-input
                                type="text"
                                required
                                v-model="item1.a_name"
                                id="Title"
                                placeholder="Name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col sm="2">
                            <label
                              for="Title"
                              class="rmv-btn-alignment"
                            ></label>
                            <b-button
                              @click="removeObj(item.auditorium, index)"
                              size="sm"
                              variant="danger"
                            >
                              <i class="fa fa-times"></i>
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="4">
                            <b-form-group>
                              <label for="Title">Description</label>
                              <b-form-textarea
                                type="text"
                                required
                                v-model="item1.a_description"
                                id="Title"
                                placeholder="Enter Desciption"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col>
                          <b-col sm="3">
                            <b-form-group>
                              <label for="Title">Row</label>
                              <b-form-input
                                type="text"
                                required
                                v-model="item1.a_row"
                                id="Title"
                                placeholder="Enter Row"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col sm="3">
                            <b-form-group>
                              <label for="Title">Col</label>
                              <b-form-input
                                type="text"
                                required
                                v-model="item1.a_col"
                                id="Title"
                                placeholder="Enter Col"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3">
                            <b-form-group>
                              <label for="Title">Max Capacity</label>
                              <b-form-input
                                type="text"
                                required
                                v-model="item1.max_capacity"
                                id="Title"
                                placeholder="Capacity"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col sm="4">
                            <b-form-group>
                              <label for="Title">Booking Type</label>
                              <b-form-select
                                class="form-control"
                                label="booking_type"
                                v-model="item1.booking_type"
                                :options="booking_type_options"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-card no-body class="mb-1">
                          <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                          >
                            <b-button
                              block
                              v-b-toggle="'accordion-3-3-' + index"
                              variant="warning"
                              >Area</b-button
                            >
                          </b-card-header>
                          <b-collapse
                            :id="'accordion-3-3-' + index"
                            accordion="my-accordion"
                            role="tabpanel"
                          >
                            <b-card-body>
                              <div
                                role="tablist"
                                v-for="(item2, index) in item1.area_type"
                                :key="index"
                              >
                                <b-row>
                                  <b-col sm="4">
                                    <b-form-group>
                                      <label for="Title">Area Name</label>
                                      <b-form-input
                                        type="text"
                                        required
                                        v-model="item2.area_name"
                                        id="Title"
                                        placeholder="Enter Name"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                  <b-col sm="6">
                                    <b-form-group>
                                      <label for="Title">Max Capacity</label>
                                      <b-form-input
                                        type="text"
                                        required
                                        v-model="item2.max_capacity"
                                        id="Title"
                                        placeholder="Enter Capacity"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                  <b-col sm="2">
                                    <label
                                      for="Title"
                                      class="rmv-btn-alignment"
                                    ></label>
                                    <b-button
                                      @click="removeObj(item1.area_type, index)"
                                      size="sm"
                                      variant="danger"
                                    >
                                      <i class="fa fa-times"></i>
                                    </b-button>
                                  </b-col>
                                </b-row>

                                <b-card no-body class="mb-1">
                                  <b-card-header
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                  >
                                    <b-button
                                      block
                                      v-b-toggle="'accordion-3-5-5-' + index"
                                      variant="warning"
                                      >Seat Type</b-button
                                    >
                                  </b-card-header>
                                  <b-collapse
                                    :id="'accordion-3-5-5-' + index"
                                    accordion="my-accordion-3-5-5-"
                                    role="tabpanel"
                                  >
                                    <b-card-body>
                                      <div
                                        role="tablist"
                                        v-for="(
                                          item3, index
                                        ) in item2.seat_type"
                                        :key="index"
                                      >
                                        <b-row>
                                          <!-- <b-col sm="4">
                                            <b-form-group>
                                              <label for="Title"
                                                >Auditorium Id</label
                                              >
                                              <b-form-input
                                                type="text"
                                                required
                                                v-model="item3.auditorium_id"
                                                id="Title"
                                                placeholder="Enter Id"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col> -->
                                          <b-col sm="8">
                                            <b-form-group>
                                              <label for="Title"
                                                >Seat Name</label
                                              >
                                              <b-form-input
                                                type="text"
                                                required
                                                v-model="item3.st_name"
                                                id="Title"
                                                placeholder="Seat Name"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col sm="2">
                                            <label
                                              for="Title"
                                              class="rmv-btn-alignment"
                                            ></label>
                                            <b-button
                                              @click="
                                                removeObj(
                                                  item2.seat_type,
                                                  index
                                                )
                                              "
                                              size="sm"
                                              variant="danger"
                                            >
                                              <i class="fa fa-times"></i>
                                            </b-button>
                                          </b-col>
                                        </b-row>
                                        <b-row>
                                          <b-col sm="4">
                                            <b-form-group>
                                              <label for="Title">Price</label>
                                              <b-form-input
                                                type="text"
                                                required
                                                v-model="item3.price"
                                                id="Title"
                                                placeholder="Enter Price"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col sm="4">
                                            <b-form-group>
                                              <label for="Title"
                                                >Currency</label
                                              >
                                              <b-form-input
                                                type="text"
                                                required
                                                v-model="item3.currency"
                                                id="Title"
                                                placeholder="Currency"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </div>
                                      <b-row>
                                        <b-col sm="3">
                                          <b-button
                                            @click="
                                              addMultiple(item2.seat_type, {
                                                auditorium_id: '1',
                                                st_name: '',
                                                price: '',
                                                currency: '',
                                              })
                                            "
                                            size="sm"
                                            variant="success"
                                          >
                                            <i class="fa fa-dot-circle-o"></i>
                                            Add Seat
                                          </b-button>
                                        </b-col>
                                      </b-row>
                                    </b-card-body>
                                  </b-collapse>
                                </b-card>
                              </div>
                              <b-row>
                                <b-col sm="3">
                                  <b-button
                                    @click="
                                      addMultiple(item1.area_type, {
                                        area_name: '',
                                        max_capacity: '',
                                        seat_type: [
                                          {
                                            auditorium_id: '1',
                                            st_name: '',
                                            price: '',
                                            currency: '',
                                          },
                                        ],
                                      })
                                    "
                                    size="sm"
                                    variant="success"
                                  >
                                    <i class="fa fa-dot-circle-o"></i> Add Area
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-card-body>
                          </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                          <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                          >
                            <b-button
                              block
                              v-b-toggle="'accordion-3-2-' + index"
                              variant="warning"
                              >Schedule</b-button
                            >
                          </b-card-header>
                          <b-collapse
                            :id="'accordion-3-2-' + index"
                            accordion="my-accordion"
                            role="tabpanel"
                          >
                            <b-card-body>
                              <div
                                role="tablist"
                                v-for="(item2, index) in item1.schedule"
                                :key="index"
                              >
                                <b-row>
                                  <!-- <b-col sm="4">
                                    <b-form-group>
                                      <label for="Title">Event Id</label>
                                      <b-form-input
                                        type="text"
                                        required
                                        v-model="item2.event_id"
                                        id="Title"
                                        placeholder="Enter Id"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col> -->
                                  <!-- <b-col sm="6">
                                    <b-form-group>
                                      <label for="Title">Auditorium Id</label>
                                      <b-form-input
                                        type="text"
                                        required
                                        v-model="item2.auditorium_id"
                                        id="Title"
                                        placeholder="Enter Id"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col> -->
                                </b-row>
                                <b-row>
                                  <b-col sm="4">
                                    <b-form-group>
                                      <label for="Title">Schedule Date</label>
                                      <b-form-input
                                        type="date"
                                        required
                                        v-model="item2.es_date"
                                        id="Title"
                                        placeholder=""
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                  <b-col sm="4">
                                    <b-form-group>
                                      <label for="Title">Schedule Time</label>
                                      <b-form-input
                                        type="time"
                                        required
                                        v-model="item2.es_time"
                                        id="Title"
                                        placeholder=""
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                  <b-col sm="2">
                                    <label
                                      for="Title"
                                      class="rmv-btn-alignment"
                                    ></label>
                                    <b-button
                                      @click="removeObj(item1.schedule, index)"
                                      size="sm"
                                      variant="danger"
                                    >
                                      <i class="fa fa-times"></i>
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </div>
                              <b-row>
                                <b-col sm="3">
                                  <b-button
                                    @click="
                                      addMultiple(item1.schedule, {
                                        auditorium_id: '1',
                                        event_id: '1',
                                        es_date: '',
                                        es_time: '',
                                      })
                                    "
                                    size="sm"
                                    variant="success"
                                  >
                                    <i class="fa fa-dot-circle-o"></i> Add
                                    Schedule
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>

                      <b-row>
                        <b-col sm="3">
                          <b-button
                            @click="
                              addMultiple(item.auditorium, {
                                event_venue_id: '1',
                                a_name: '',
                                a_description: '',
                                a_row: '',
                                a_col: '',
                                booking_type: '',
                                max_capacity: '',
                                area_type: [
                                  {
                                    area_name: '',
                                    max_capacity: '',
                                    seat_type: [
                                      {
                                        auditorium_id: '1',
                                        st_name: '',
                                        price: '',
                                        currency: '',
                                      },
                                    ],
                                  },
                                ],
                                seat_layout: [],
                                schedule: [
                                  {
                                    auditorium_id: '1',
                                    event_id: '1',
                                    es_date: '',
                                    es_time: '',
                                  },
                                ],
                              })
                            "
                            size="sm"
                            variant="success"
                          >
                            <i class="fa fa-dot-circle-o"></i> Add Auditorium
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <b-row>
                <b-col sm="3">
                  <b-button
                    @click="
                      addMultiple(event_info.venue, {
                        ev_name: '',
                        ev_address: '',
                        ev_lat: '',
                        ev_long: '',
                        ev_city: '',
                        ev_country: '',
                        auditorium: [
                          {
                            event_venue_id: '1',
                            a_name: '',
                            a_description: '',
                            a_row: '',
                            a_col: '',
                            booking_type: '',
                            max_capacity: '',
                            area_type: [
                              {
                                area_name: '',
                                max_capacity: '',
                                seat_type: [
                                  {
                                    auditorium_id: '1',
                                    st_name: '',
                                    price: '',
                                    currency: '',
                                  },
                                ],
                              },
                            ],
                            seat_layout: [],
                            schedule: [
                              {
                                auditorium_id: '1',
                                event_id: '1',
                                es_date: '',
                                es_time: '',
                              },
                            ],
                          },
                        ],
                      })
                    "
                    size="sm"
                    variant="success"
                  >
                    <i class="fa fa-dot-circle-o"></i> Add Venue
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Event Manager Tab Start -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-8-em
              variant="info"
              :disabled="event_info.country_id ? false : true"
              >Event Manager</b-button
            >
          </b-card-header>
          <b-collapse
            id="accordion-8-em"
            accordion="my-accordion-8-em"
            role="tabpanel"
          >
            <b-card-body>
              <b-row>
                <b-col sm="4">
                  <b-form-group>
                    <label for="Title">Select Event Manager</label>
                    <multiselect
                      v-model="selectedEventManager"
                      :options="$store.getters['common/getAllEventManager']"
                      track-by="event_manager_id"
                      label="em_name"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :select-label="''"
                      :deselect-label="''"
                      :selectedLabel="''"
                    ></multiselect>
                    <!-- <b-form-select
                      v-model="selectedEventManager"
                      id="em_name"
                      :plain="true"
                      text-field="em_name"
                      value-field="event_manager_id"
                      :options="$store.getters['common/getAllEventManager']"
                    >
                      <template slot="first">
                        <option :value="null">Select Event Manager</option>
                      </template>
                    </b-form-select> -->
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- Event Manager Tab End -->
      </div>

      <b-button
        v-if="this.$route.query.event_id == null"
        @click="handleSubmit()"
        size="sm"
        variant="success"
      >
        SUBMIT
      </b-button>
      <b-button
        v-if="this.$route.query.event_id"
        @click="handleSubmit()"
        size="sm"
        variant="success"
      >
        Update
      </b-button>
    </div>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";
import UploadService from "@/services/UploadService";
import { Switch as cSwitch } from "@coreui/vue";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      event_info: {
        country_id: null,
        e_public: 1,
        e_name: "",
        e_s_description: "",
        e_description: "",
        e_image: "",
        es_date: "",
        ee_date: "",
        e_book_type: "null",
        terms_condition: "",
        booking_open: true,
        is_unlisted: true,
        is_free: true,
        stop_sale: false,
        ec_id: "1",
        additional_information: "null",
        rating: "",
        e_genreData: [
          {
            e_genreData: "",
          },
        ],
        cast_crew: [
          {
            cc_name: "cc_name",
            cc_type: "cc_type",
            cc_picture: "cc_picture",
          },
        ],
        e_banners: [
          {
            e_banners: "",
          },
        ],
        e_videos: [
          {
            e_videos: "",
          },
        ],
        organiser_details: [
          {
            od_name: "",
            od_sdescription: "",
            od_description: "",
            od_logo: "",
            od_email: "",
            od_mobile: "",
          },
        ],
        sponsor_details: [
          {
            event_id: "event_id",
            sd_name: "sd_name",
            sd_sdescription: "sd_sdescription",
            sd_description: "sd_description",
            sd_logo: "sd_logo",
            sd_email: "sd_email",
            sd_mobile: "123456789",
          },
        ],
        venue: [
          {
            event_id: "1",
            ev_name: "",
            ev_address: "",
            ev_lat: "",
            ev_long: "",
            ev_city: "",
            ev_country: "",
            auditorium: [
              {
                event_venue_id: "1",
                a_name: "",
                a_description: "",
                a_row: "0",
                a_col: "0",
                booking_type: "null",
                max_capacity: "0",
                area_type: [
                  {
                    area_name: "",
                    max_capacity: "",
                    seat_type: [
                      {
                        auditorium_id: "1",
                        st_name: "",
                        price: "",
                        currency: "",
                      },
                    ],
                  },
                ],
                seat_layout: [],
                schedule: [
                  {
                    auditorium_id: "1",
                    event_id: "1",
                    es_date: "",
                    es_time: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      cc_type_options: [
        { value: null, text: "Please select an option" },
        { value: "Production", text: "Production" },
        { value: "Direction", text: "Direction" },
      ],
      booking_type_options: [
        { value: null, text: "Please select an option" },
        { value: "Open", text: "Open" },
        { value: "Close", text: "Close" },
      ],
      max_char: 256,
      // selectedCountry: null,
      selectedEventManager: [],
      country_options: [],
    };
  },
  beforeMount() {
    this.$store.dispatch("common/setAllEventManager");
    MasterService.getCountryEvent()
      .then((response) => {
        console.log(response.data.List, "countryyyyy");
        response.data.List.map((item) => {
          item.value = item.CountryID;
          item.text = item.CountryName;
          if (item.CountryName === "UAE") {
            item.disabled = true;
          }
          this.country_options.push(item);
        });
      })
      .catch((error) => {
        console.log("Catch on Getting Country Event Error: ", error);
      });
  },

  components: {
    cSwitch,
    VueEditor,
    // Editor,
  },
  mounted() {
    this.getAllEventInfo();
  },

  methods: {
    getAllEventInfo() {
      if (this.$route.query.event_id) {
        console.log(this.$route.query.event_id);

        MasterService.updateEventAllData({
          id: this.$route.query.event_id,
        })
          .then((response) => {
            let data = response.data.data;
            console.log("Happy singh=>", data);
            // alert(data.is_free);

            let gen = data.e_genre.map((el) => {
              return el.e_genre;
            });
            let bannerImage = data.e_banners.map((el) => {
              return el.e_banner;
            });
            let eventVideo = data.e_videos.map((el) => {
              return el.e_video;
            });

            let allVideoId = eventVideo.map((el) => {
              let videoData = {
                e_videos: el,
              };
              return videoData;
            });

            this.event_info = {
              country_id: data.country_id,
              e_public: data.e_public,
              e_name: data.e_name,
              e_s_description: data.e_s_description,
              e_description: data.e_description,
              e_image: data.e_image,
              es_date: data.es_date,
              ee_date: data.ee_date,
              e_book_type: data.e_book_type,
              terms_condition: data.terms_condition,
              booking_open: this.ReturnBookingOpen(data.booking_open),
              is_unlisted: data.is_unlisted == 1 ? true : false,
              is_free: data.is_free == 1 ? true : false,
              stop_sale: data.stop_sale == 1 ? true : false,
              ec_id: data.ec_id,
              additional_information: data.additional_information,
              rating: data.rating,
              e_genreData: [
                {
                  e_genreData: gen.toString(),
                },
              ],
              cast_crew: [
                {
                  cc_name: "cc_name",
                  cc_type: "cc_type",
                  cc_picture: "cc_picture",
                },
              ],
              e_banners: [
                {
                  e_banners: bannerImage.toString(),
                },
              ],
              e_videos: allVideoId,
              organiser_details: data.organiser_details,
              sponsor_details: data.sponsor_details,
              venue: data.venue,
            };
            let event_managerArr = [];
            if (data.event_manager) {
              event_managerArr = data.event_manager.split(",");
            }
            this.$store.getters["common/getAllEventManager"].forEach(
              (val, index) => {
                if (
                  (val.event_manager_id != undefined &&
                    val.event_manager_id != null) ||
                  val.event_manager_id != "undefined"
                ) {
                  event_managerArr.forEach((newVal, newIndex) => {
                    if (newVal == val.event_manager_id) {
                      this.selectedEventManager.push(val);
                    }
                  });
                }
              }
            );
            // this.selectedEventManager = data.event_manager;
          })
          .catch((error) => {
            console.log("Catch on Getting Event Error: ", error);
          });
      }
    },

    addMultiple(var_Arr, var_obj) {
      var_Arr.push(var_obj);
    },

    removeObj(var_Arr, index) {
      var_Arr.splice(index, 1);
    },

    ReturnTrueFalse(BooleanValue) {
      if (BooleanValue == true) {
        return 1;
      } else {
        return 2;
      }
    },

    ReturnBookingOpen(data) {
      if (data == 1) {
        return true;
      } else {
        return false;
      }
    },

    ReturnfreeEvent(data) {
      if (data == 2) {
        return true;
      } else {
        return false;
      }
    },
    ReturnfreeEventTrueFalse(BooleanValue) {
      if (BooleanValue == 2) {
        return true;
      } else {
        return false;
      }
    },

    getVideoId(url) {
      console.log(url.length, "ashahd");
      let youtubeData = [];

      if (url && url.length > 0) {
        url.map((el, i) => {
          let data = el.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
          let newId =
            data[2] !== undefined ? data[2].split(/[^0-9a-z_\-]/i)[0] : data[0];
          youtubeData.push(newId);
        });
      }
      return youtubeData;
    },

    // handleFileUpload(var_Arr, event, index) {

    //   let files_name;

    //   if (event.target.files[0].size >= 2000000) {
    //     return this.showMessageSnackbar("file size should be less than 2MB");
    //   } else {
    //     files_name = event.target.files[0];
    //   }

    //   let formData = new FormData();

    //   if (var_Arr == "e_videos") {
    //     formData.append("assets/videos/media", files_name);
    //   } else {
    //     formData.append("assets/images/movies/banners/", files_name);
    //   }

    //   for (var [key, value] of formData.entries()) {
    //     console.log(key, value);
    //   }

    //   UploadService.uploadImages(formData)
    //     .then((response) => {
    //       console.log("Response!!=>", response.data);
    //       if (response.data.Status == true) {
    //         this.showMessageSnackbar("File Uploaded Succesfully");
    //       } else {
    //         this.showMessageSnackbar(response.data.Message);
    //       }
    //       console.log("SUCCESS!!=>", response.data.Path);
    //       if (var_Arr == "event_info.e_image") {
    //         this.event_info.e_image = response.data.Path;
    //       } else if (var_Arr == "e_banners") {
    //         this.event_info.e_banners[index]["e_banners"] = response.data.Path;
    //       } else if (var_Arr == "e_videos") {
    //         this.event_info.e_videos[index]["e_videos"] = response.data.Path;
    //       } else if (var_Arr == "cc_picture") {
    //         this.event_info.cast_crew[index]["cc_picture"] = response.data.Path;
    //       } else if (var_Arr == "od_logo") {
    //         this.event_info.organiser_details[index]["od_logo"] =
    //           response.data.Path;
    //       } else if (var_Arr == "sd_logo") {
    //         this.event_info.sponsor_details[index]["sd_logo"] =
    //           response.data.Path;
    //       }
    //     })
    //     .catch(function (err) {
    //       console.log("Error in image upload ", err);
    //     });
    // },

    handleFileUpload(var_Arr, event, index) {
      let file = event.target.files[0];
      console.log(var_Arr, "sdajsdkn");

      let reader = new FileReader();
      let width = 0;
      let height = 0;

      reader.readAsDataURL(file);

      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          width = img.width;
          height = img.height;
          if (
            (height == 488 &&
              width == 325 &&
              var_Arr == "event_info.e_image") ||
            (height >= 575 && width >= 1920 && var_Arr == "e_banners") ||
            (height >= 100 && width >= 100 && var_Arr == "od_logo")
          ) {
            // this.showMessageSnackbar('Valid')
            let files_name;
            if (event.target.files[0].size >= 2000000) {
              return this.showMessageSnackbar(
                "File size should be less than 2MB"
              );
            } else {
              files_name = event.target.files[0];
            }

            let formData = new FormData();

            if (var_Arr == "e_videos") {
              formData.append("assets/videos/media", files_name);
            } else {
              formData.append("assets/images/movies/banners/", files_name);
            }

            UploadService.uploadImages(formData)
              .then((response) => {
                console.log("Response!!=>", response.data);
                if (response.data.Status == true) {
                  this.showMessageSnackbar("File Uploaded Succesfully");
                } else {
                  this.showMessageSnackbar(response.data.Message);
                }
                console.log("SUCCESS!!=>", response.data.Path);
                if (var_Arr == "event_info.e_image") {
                  this.event_info.e_image = response.data.Path;
                } else if (var_Arr == "e_banners") {
                  this.event_info.e_banners[index]["e_banners"] =
                    response.data.Path;
                } else if (var_Arr == "e_videos") {
                  this.event_info.e_videos[index]["e_videos"] =
                    response.data.Path;
                } else if (var_Arr == "cc_picture") {
                  this.event_info.cast_crew[index]["cc_picture"] =
                    response.data.Path;
                } else if (var_Arr == "od_logo") {
                  this.event_info.organiser_details[index]["od_logo"] =
                    response.data.Path;
                } else if (var_Arr == "sd_logo") {
                  this.event_info.sponsor_details[index]["sd_logo"] =
                    response.data.Path;
                }
              })
              .catch(function (err) {
                console.log("Error in image upload ", err);
              });
          } else {
            this.showMessageSnackbar("Enter Valid Image");
          }
        };
        img.src = evt.target.result;
      };
    },

    // handleVideoFileUpload(var_Arr, event, index) {

    //         let files_name;
    //         if (event.target.files[0].size >= 5000000) {
    //           return this.showMessageSnackbar("File size should be less than 5MB");
    //         } else {
    //           files_name = event.target.files[0];
    //         }

    //         let formData = new FormData();

    //         if (var_Arr == "e_videos") {
    //           formData.append("assets/videos/media", files_name);
    //         } else {
    //           formData.append("assets/images/movies/banners/", files_name);
    //         }

    //         UploadService.uploadImages(formData)
    //           .then((response) => {
    //             console.log("Response!!=>", response.data);
    //             if (response.data.Status == true) {
    //               this.showMessageSnackbar("File Uploaded Succesfully");
    //             } else {
    //               this.showMessageSnackbar(response.data.Message);
    //             }
    //             console.log("SUCCESS!!=>", response.data.Path);
    //             if (var_Arr == "e_videos") {
    //               this.event_info.e_videos[index]["e_videos"] = response.data.Path;
    //             }
    //           })
    //           .catch(function (err) {
    //             console.log("Error in image upload ", err);
    //           });
    // },

    RedirectToViewInfo() {
      this.$router.push("/eventinfo/vieweventinfo");
    },

    handleSubmit() {
      let gen = this.event_info.e_genreData.map((el) => {
        return el.e_genreData;
      });

      let bannerImg = this.event_info.e_banners.map((el) => {
        return el.e_banners;
      });

      let eventVideo = this.event_info.e_videos.map((el) => {
        return el.e_videos;
      });

      let youtubeId = this.getVideoId(eventVideo);

      let booking_open_value = this.ReturnTrueFalse(
        this.event_info.booking_open
      );
      // let is_unlisted_value = this.ReturnfreeEventTrueFalse(
      //   this.event_info.is_unlisted
      // );
      // let is_free_value = this.ReturnfreeEventTrueFalse(
      //   this.event_info.is_free
      // );

      let is_unlisted_value = this.event_info.is_unlisted;
      let is_free_value = this.event_info.is_free;
      let stop_sale_value = this.event_info.stop_sale;

      let newEventManager = [];
      console.log(this.selectedEventManager, "cid");
      this.selectedEventManager.forEach((val, index) => {
        if (
          (val.event_manager_id != undefined && val.event_manager_id != null) ||
          val.event_manager_id != "undefined"
        ) {
          newEventManager.push(val.event_manager_id);
        }
      });
      this.selectedEventManager = newEventManager;

      if (
        this.event_info.booking_open == true &&
        this.selectedEventManager.length <= 0
      ) {
        this.showMessageSnackbar("Please Select Event Manager");
        return false;
      }

      let payload = {
        country_id: this.event_info.country_id,
        e_public: this.event_info.e_public,
        e_name: this.event_info.e_name,
        e_s_description: this.event_info.e_s_description,
        e_description: this.event_info.e_description,
        e_image: this.event_info.e_image,
        es_date: this.event_info.es_date,
        ee_date: this.event_info.ee_date,
        e_book_type: this.event_info.e_book_type,
        ec_id: this.event_info.ec_id,
        additional_information: this.event_info.additional_information,
        rating: this.event_info.rating,
        terms_condition: this.event_info.terms_condition,
        booking_open: booking_open_value,
        is_unlisted: is_unlisted_value == true ? 1 : 2,
        is_free: is_free_value == true ? 1 : 2,
        stop_sale: stop_sale_value == true ? 1 : 2,
        e_genre: gen,
        cast_crew: this.event_info.cast_crew,
        e_banners: bannerImg,
        e_videos: youtubeId,
        organiser_details: this.event_info.organiser_details,
        sponsor_details: this.event_info.sponsor_details,
        venue: this.event_info.venue,
        eventManager: this.selectedEventManager.toString(),
      };
      // console.log(
      //   "this.selectedEventManager.toString()=>",
      //   this.selectedEventManager
      // );
      // return false;
      if (this.$route.query.event_id) {
        MasterService.updateEvent(payload, this.$route.query.event_id)
          .then((response) => {
            const { data } = response;
            console.log("response", response);
            this.showMessageSnackbar(
              data.message
                ? data.message
                : data.error
                ? data.error.message
                  ? data.error.message
                  : data.error
                : data
            );

            if (data.message) {
              this.RedirectToViewInfo();
            }
          })
          .catch((error) => {
            console.log("Catch on Updating Event Error: ", error);
          });
      } else {
        MasterService.addEvent(payload)
          .then((response) => {
            const { data } = response;
            // console.log(data.message, "datamsg")
            // console.log(data.error, "dataerrmsg")
            // console.log(data.error, "dataerr")

            console.log("response Add", data);

            // alert(data.message ? data.message : data.error ? data.error.message : data.error)

            this.showMessageSnackbar(
              data.message
                ? data.message
                : data.error
                ? data.error.message
                  ? data.error.message
                  : data.error
                : data
            );

            if (data.message) {
              this.RedirectToViewInfo();
            }

            // if(data.message){
            //   alert(data.message);
            //   alert("Hiii")
            //   console.log("gethereee")
            // }
            // else if(data.error.message){
            //   alert(data.error.message);
            // }
            // else if(data.error) {
            //   alert(data.error.message);
            // }
          })
          .catch((error) => {
            console.log("Catch on Adding Event Error: ", error);
          });
      }
    },
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style>
.card-header {
  background: none !important;
}

.status-alignment {
  margin-top: 1.3rem;
}
.rmv-btn-alignment {
  margin-top: 2.5rem;
}
</style>
